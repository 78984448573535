<template>
  <div class="manual--sales-record-basic-settings">
    <SectionTitle title="儲值金付款設定" @edit="modal.edit = true" />
    <BaseElForm label-position="left" label-width="220px">
      <BaseElFormItem
        v-for="info in displayData"
        :key="info.label"
        :label="info.label"
      >
        {{ info.value }}
      </BaseElFormItem>
    </BaseElForm>

    <ManualSalesRecordWalletSettingsEditModal
      v-if="modal.edit"
      :configData="configData"
      @close="modal.edit = false"
      @refresh="$emit('refresh')"
    />
  </div>
</template>

<script>
import { defineComponent, ref, computed, reactive } from 'vue'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import ManualSalesRecordWalletSettingsEditModal from './ManualSalesRecordWalletSettingsEditModal.vue'
import { get, find } from 'lodash'

export default defineComponent({
  name: 'ManualSalesRecordWalletSettings',
  components: { SectionTitle, ManualSalesRecordWalletSettingsEditModal },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const loadig = ref(false)
    const modal = reactive({
      edit: false,
    })
    const displayData = computed(() => {
      const paymentSettings = get(props.configData, 'paymentSettings')
      const config = find(paymentSettings, { type: 'wallet' })
      const data = {
        enable: get(config, 'enable'),
        defaultUseEnable: get(config, 'defaultUseEnable'),
        defaultUseRate: get(config, 'defaultUseRate'),
        useRateEnable: get(config, 'useRateEnable'),
        useRateLimit: get(config, 'useRateLimit'),
      }
      return [
        { label: '開放使用儲值金付款', value: data.enable ? '開啟' : '關閉' },
        { label: '啟用訂單預設付款', value: data.defaultUseEnable ? '開啟' : '關閉' },
        { label: '預設付款', value: data.defaultUseRate ? `${Number(data.defaultUseRate) * 100}%` : '-' },
        { label: '啟用訂單付款上限', value: data.useRateEnable ? '開啟' : '關閉' },
        { label: '付款上限', value: data.useRateLimit ? `${Number(data.useRateLimit) * 100}%` : '-' },
      ]
    })
    return {
      modal,
      loadig,
      displayData,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
