<template>
  <BaseDialog title="編輯基本設定" :btn1Loading="loading" @close="$emit('close')" @confirm="onConfirm">
    <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top">
      <BaseElFormItem prop="enable">
        <template slot="label">
          <FormItemTooltipLabel label="開放客人進行消費登錄" :tooltipWidth="200">
            開啟後，客人將可透過連結進行消費登錄折抵回饋金或使用儲值金付款，且可透過銷售回饋活動設定獲得獎勵
          </FormItemTooltipLabel>
        </template>
        <BaseElSwitch
          v-model="formData.enable"
          inactive-text="關閉"
          active-text="開啟"
        />
      </BaseElFormItem>
      <BaseElFormItem label="綁定銷售產品" prop="product">
        <SalesProductSelect :model.sync="formData.product" />
      </BaseElFormItem>
      <BaseElFormItem prop="allowClientEditPrice">
        <template slot="label">
          <FormItemTooltipLabel label="開放客人修改產品金額" :tooltipWidth="200">
            關閉時，將根據後台產品設定的預設售價登錄消費金額；開啟時，將開放客人可自行輸入產品金額
          </FormItemTooltipLabel>
        </template>
        <BaseElSwitch
          v-model="formData.allowClientEditPrice"
          inactive-text="關閉"
          active-text="開啟"
        />
      </BaseElFormItem>
    </BaseElForm>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import SalesProductSelect from '@/components/Search/sales/SalesProductSelect.vue'
import { useBaseForm } from '@/use/useForm'
import { noEmptyRules } from '@/validation'
import { computed, defineComponent, onMounted, set } from 'vue'
import { UpdateSalesClientConfig } from '@/api/sales'
import { useShop } from '@/use/shop'
import { get } from 'lodash'

export default defineComponent({
  name: 'ManualSalesRecordBasicSettingsEditModal',
  components: { BaseDialog, FormItemTooltipLabel, SalesProductSelect },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  emits: ['refresh', 'close'],
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { initFormData, formData, formRef, onSubmit, loading } = useBaseForm()
    initFormData({
      enable: false,
      product: null,
      allowClientEditPrice: false,
    })
    const formRules = {
      enable: [noEmptyRules()],
      product: [noEmptyRules()],
      allowClientEditPrice: [noEmptyRules()],
    }

    const submitData = computed(() => {
      const { enable, allowClientEditPrice, product } = formData
      return {
        shopId: shopId.value,
        enable,
        productSelectMode: 'SINGLE',
        SingleProductId: get(product, 'id') || undefined,
        allowClientEditPrice,
        // paymentSettings: get(props.configData, 'paymentSettings'),
        // discountSettings: get(props.configData, 'discountSettings'),
      }
    })

    const handleSubmit = async (data) => {
      const [res, err] = await UpdateSalesClientConfig({
        shopId: shopId.vavlue,
        ...data,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功！')
      emit('refresh')
      emit('close')
    }

    const syncData = () => {
      const config = props.configData
      formData.enable = get(config, 'enable')
      formData.product = get(config, 'SingleProduct')
      formData.allowClientEditPrice = get(config, 'allowClientEditPrice')
    }

    const onConfirm = async () => {
      await onSubmit(handleSubmit, submitData.value)
    }

    onMounted(() => {
      syncData()
    })
    return { formData, formRef, formRules, onConfirm, loading }
  },
})
</script>

<style lang="postcss" scoped>

</style>
