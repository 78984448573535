<template>
  <BaseDialog width="610px" title="儲值金付款設定" @close="$emit('close')" @confirm="onConfirm">
    <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top">
      <BaseElFormItem prop="enable">
        <template slot="label">
          <FormItemTooltipLabel label="開放使用儲值金付款" :tooltipWidth="200">
            假設設定預設付款為 10%，客人進入消費登錄頁面後，系統將預設付款訂單金額 10% 的儲值金
          </FormItemTooltipLabel>
        </template>
        <BaseElSwitch
          v-model="formData.enable"
          inactive-text="關閉"
          active-text="開啟"
        />
      </BaseElFormItem>

      <template v-if="formData.enable">
        <!-- 預設付款 -->
        <BaseElFormItem prop="defaultUseEnable">
          <template slot="label">
            <FormItemTooltipLabel label="啟用訂單預設付款" :tooltipWidth="200">
              假設設定付款上限為 15%，客人進入消費登錄頁面後，不可使用超過訂單金額 15% 的儲值金付款
              注意：付款上限不可以小於預設付款金額
            </FormItemTooltipLabel>
          </template>
          <BaseElSwitch
            v-model="formData.defaultUseEnable"
            inactive-text="關閉"
            active-text="開啟"
          />
        </BaseElFormItem>
        <BaseElFormItem label="預設付款（%）" prop="defaultUseRate">
          <BaseElInput v-model="formData.defaultUseRate" :disabled="!formData.defaultUseEnable" clearable placeholder="請填寫數字" />
        </BaseElFormItem>

        <!-- 付款上限 -->
        <BaseElFormItem prop="useRateEnable">
          <template slot="label">
            <FormItemTooltipLabel label="啟用訂單付款上限" :tooltipWidth="200">
              假設設定付款上限為 15%，客人進入消費登錄頁面後，不可使用超過訂單金額 15% 的儲值金付款
              注意：付款上限不可以小於預設付款金額
            </FormItemTooltipLabel>
          </template>
          <BaseElSwitch
            v-model="formData.useRateEnable"
            inactive-text="關閉"
            active-text="開啟"
          />
        </BaseElFormItem>
        <BaseElFormItem label="付款上限（%）" prop="useRateLimit">
          <BaseElInput v-model="formData.useRateLimit" :disabled="!formData.useRateEnable" clearable placeholder="請填寫數字" />
        </BaseElFormItem>
      </template>
    </BaseElForm>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import { useBaseForm } from '@/use/useForm'
import { isDigitRules, noEmptyRules, rangeRules } from '@/validation'
import { defineComponent, computed, onMounted } from 'vue'
import { UpdateSalesClientConfig } from '@/api/sales'
import { get, filter, find } from 'lodash'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'ManualSalesRecordWalletSettingsEditModal',
  components: { BaseDialog, FormItemTooltipLabel },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { initFormData, formData, formRef, onSubmit, loading } = useBaseForm()
    initFormData({
      enable: false,
      defaultUseEnable: false,
      defaultUseRate: null,
      useRateEnable: false,
      useRateLimit: null,
    })
    const formRules = computed(() => {
      const rules = {
        enable: [noEmptyRules()],
      }

      if (formData.defaultUseEnable) {
        rules.defaultUseRate = [noEmptyRules(), isDigitRules(), rangeRules(0, 100)]
      }
      if (formData.useRateEnable) {
        let minRange = 0
        if (formData.defaultUseEnable && formData.defaultUseRate) {
          minRange = Number(formData.defaultUseRate)
        }
        rules.useRateLimit = [noEmptyRules(), isDigitRules(), rangeRules(minRange, 100)]
      }

      return rules
    })
    const submitData = computed(() => {
      const paymentSettings = get(props.configData, 'paymentSettings')
      const settingsData = filter(paymentSettings, setting => setting.type !== 'wallet')
      return {
        shopId: shopId.value,
        paymentSettings: [
          ...settingsData,
          {
            name: '儲值金',
            type: 'wallet',
            order: 1,
            enable: formData.enable,
            useRateLimit: Number(formData.useRateLimit) / 100,
            useRateEnable: formData.useRateEnable,
            defaultUseRate: Number(formData.defaultUseRate) / 100,
            defaultUseEnable: formData.defaultUseEnable,
          },
        ],
      }
    })
    const handleSubmit = async (data) => {
      const [res, err] = await UpdateSalesClientConfig({
        shopId: shopId.vavlue,
        ...data,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功！')
      emit('refresh')
      emit('close')
    }
    const onConfirm = async () => {
      await onSubmit(handleSubmit, submitData.value)
    }
    const syncData = () => {
      const paymentSettings = get(props.configData, 'paymentSettings')
      const config = find(paymentSettings, { type: 'wallet' })
      const defaultUseRate = get(config, 'defaultUseRate')
      const useRateLimit = get(config, 'useRateLimit')

      formData.enable = get(config, 'enable') || false
      formData.defaultUseEnable = get(config, 'defaultUseEnable') || false
      formData.defaultUseRate = defaultUseRate ? defaultUseRate * 100 : null
      formData.useRateEnable = get(config, 'useRateEnable') || false
      formData.useRateLimit = useRateLimit ? useRateLimit * 100 : null
    }
    onMounted(() => {
      syncData()
    })
    return { formData, formRef, formRules, onConfirm, loading }
  },
})
</script>

<style lang="postcss" scoped>

</style>
