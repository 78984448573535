<template>
  <BaseDialog width="610px" title="編輯現場付款設定" @close="$emit('close')" @confirm="onConfirm">
    <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top">
      <BaseElFormItem prop="enable" label="開放使用現場付款">
        <BaseElSwitch
          v-model="formData.enable"
          inactive-text="關閉"
          active-text="開啟"
        />
      </BaseElFormItem>
    </BaseElForm>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useShop } from '@/use/shop'
import { useBaseForm } from '@/use/useForm'
import { isDigitRules, noEmptyRules, rangeRules } from '@/validation'
import { UpdateSalesClientConfig } from '@/api/sales'
import { computed, defineComponent, onMounted, set } from 'vue'
import { get, filter, find } from 'lodash'
export default defineComponent({
  name: 'ManualSalesRecordOfflinePaymentSettingsEditModal',
  components: { BaseDialog },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { initFormData, formData, formRef, onSubmit, loading } = useBaseForm()
    initFormData({
      enable: false,
    })
    const formRules = computed(() => {
      const rules = {
        enable: [noEmptyRules()],
      }

      return rules
    })

    const submitData = computed(() => {
      const paymentSettings = get(props.configData, 'paymentSettings')
      const settingsData = filter(paymentSettings, setting => setting.type !== 'offline')

      return {
        shopId: shopId.value,
        paymentSettings: [
          ...settingsData,
          {
            name: '現場付款',
            type: 'offline',
            order: 1,
            enable: formData.enable,
            // useRateLimit: Number(formData.useRateLimit) / 100,
            // useRateEnable: formData.useRateEnable,
            // defaultUseRate: Number(formData.defaultUseRate) / 100,
            // defaultUseEnable: formData.defaultUseEnable,
          },
        ],
      }
    })
    const handleSubmit = async (data) => {
      const [res, err] = await UpdateSalesClientConfig({
        shopId: shopId.vavlue,
        ...data,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功！')
      emit('refresh')
      emit('close')
    }

    const syncData = () => {
      const paymentSettings = get(props.configData, 'paymentSettings')
      const config = find(paymentSettings, { type: 'offline' })

      formData.enable = get(config, 'enable') || false
    }

    const onConfirm = async () => {
      await onSubmit(handleSubmit, submitData.value)
    }
    onMounted(() => {
      syncData()
    })
    return { formData, formRef, formRules, onConfirm, loading }
  },
})
</script>

<style lang="postcss" scoped>

</style>
